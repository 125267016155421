import { Suspense } from 'react'

import * as serviceWorker from './resources/serviceWorker'

import { lazy } from 'helpers/lazy'

import { Loader } from 'components/Loader/Loader'

const { registerLicense } = await import('@syncfusion/ej2-base')

import('styles/index.less')

import('./resources/i18n')

const GraphQlClient = lazy(
  () => import('./resources/GraphQlClient'),
  'GraphQlClient'
)
const ErrorBoundary = lazy(
  () => import('./resources/ErrorBoundary'),
  'ErrorBoundary'
)
const Reloader = lazy(() => import('./resources/Reloader'), 'Reloader')
const BreakpointProvider = lazy(
  () => import('components/BreakpointContext'),
  'BreakpointProvider'
)
const AntDesign = lazy(() => import('./resources/AntDesign'), 'AntDesign')
const App = lazy(() => import('./App'), 'App')

registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE_KEY)

export const Main = properties => (
  <Suspense fallback={<Loader />}>
    {[
      GraphQlClient,
      ErrorBoundary,
      Reloader,
      BreakpointProvider,
      AntDesign,
    ].reduceRight(
      (children, Component) => (
        <Component {...{ children }} />
      ),
      <App {...properties} />
    )}
  </Suspense>
)

serviceWorker.unregister()
